import { Timestamp } from 'firebase/firestore';

export const formatDateValues = (data) => {
    const formattedDates = {};

    for (const key in data) {
        const value = data[key];
        if (value instanceof Timestamp) {
            formattedDates[key] = value.toDate().toISOString();
        }
    }

    return formattedDates;
};
