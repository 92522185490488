import notFoundImg from 'assets/images/dashboard/not-found.png';

const NotFound = ({ className, imageClassName, message = '' }) => {
    return (
        <div className={`-translate-y-1 w-max mx-auto ${className}`}>
            <img
                src={notFoundImg}
                alt="No trades found"
                className={`h-[150px] w-[150px] translate-x-1.5 ${imageClassName}`}
            />
            <p className="font-semibold text-text-light text-base 400:text-lg -mt-2">{message}</p>
        </div>
    );
};

export default NotFound;
