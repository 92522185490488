import { forwardRef } from 'react';

const TableItem = forwardRef(({ moreThanOne, className, children }, ref) => {
    return (
        <tr
            className={`[&>*]:px-5 [&>*]:py-3 [&>*]:flex [&>*]:items-center ${
                moreThanOne && 'border-b'
            } text-text-light transition-colors hover:bg-[#f5f5f5] [&>*:first-of-type]:pl-3 [&>*]:font-medium grid ${className}`}
            ref={ref}
        >
            {children}
        </tr>
    );
});

export default TableItem;
