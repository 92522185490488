import Loader from 'components/Loader';
import { Nav } from 'components/Nav';
import SideBar from 'components/SideBar';
import ROUTES from 'constants/routes';
import { doc, onSnapshot } from 'firebase/firestore';
import useScreenSize from 'hooks/useScreenSize';
import useUser from 'hooks/useUser';
import { useEffect } from 'react';
import { Suspense, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { db, getUser, setUser } from 'utils';
import { formatDateValues } from 'utils/formatDateValues';

const DashboardLayout = () => {
    const screenSize = useScreenSize();
    const mobileScreen = screenSize <= 640;
    const [sideBarOpen, setSideBarOpen] = useState(mobileScreen ? false : true);
    const [resizedBelowMobile, setResizedBelowMobile] = useState(mobileScreen ? true : false);
    const { user } = useUser();

    useEffect(() => {
        if (getUser()?.id) {
            const unSubAutoUpdateRole = onSnapshot(doc(db, 'users', getUser().id), (doc) => {
                doc.exists() && setUser({ ...doc.data(), ...formatDateValues(doc.data()) });
            });

            return () => {
                unSubAutoUpdateRole();
            };
        }
    }, []);

    useEffect(() => {
        const handleResizeSidebar = () => {
            if (mobileScreen) {
                if (!sideBarOpen) {
                    setResizedBelowMobile(true);
                    return;
                }
                if (sideBarOpen && !resizedBelowMobile) {
                    setSideBarOpen(false);
                    setResizedBelowMobile(true);
                }
            } else {
                if (!sideBarOpen) {
                    setSideBarOpen(true);
                }
                setResizedBelowMobile(false);
            }
        };

        window.addEventListener('resize', handleResizeSidebar);
        return () => {
            window.removeEventListener('resize', handleResizeSidebar);
        };
    }, [mobileScreen, sideBarOpen, resizedBelowMobile]);

    if (!user) {
        return <Navigate replace to={ROUTES.LOGIN} />;
    }

    return (
        <>
            <Nav setSideBarOpen={setSideBarOpen} sideBarOpen={sideBarOpen} />
            <div
                className={`grid h-[calc(100vh-58px)] overflow-y-hidden transition-all duration-300 ${
                    sideBarOpen ? 'grid-cols-[230px_1fr]' : 'grid-cols-[75px_1fr]'
                }`}
            >
                <SideBar sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen} mobileScreen={mobileScreen} />

                <main
                    className={`${
                        sideBarOpen && 'max-sm:col-span-2'
                    } h-full overflow-y-auto bg-[#FAFBFE] py-7 px-3 475:px-7`}
                    id="main-wrapper"
                >
                    <Suspense
                        fallback={
                            <div className="h-full w-full flex items-center justify-center">
                                <Loader />
                            </div>
                        }
                    >
                        <Outlet />

                        <footer className="text-right pt-4">
                            © Copyright Agro Market Square {new Date().getFullYear()} <br />
                            Built by{' '}
                            <a
                                href="https://kreatixtech.com/"
                                title="Kreatix Technologies"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Kreatix Technologies
                            </a>
                        </footer>
                    </Suspense>
                </main>
            </div>
        </>
    );
};

export default DashboardLayout;
