import { Navigate, Outlet } from 'react-router-dom';
import signInImg from 'assets/images/sign-in.jpg';
import agroLogo from 'assets/images/agrologo.jpg';
import ROUTES from 'constants/routes';
import useUser from 'hooks/useUser';

const OnboardLayout = () => {
    const { user } = useUser();

    if (user) {
        return <Navigate to={ROUTES.HOMEPAGE} replace />;
    }

    return (
        <div className="grid md:grid-cols-[50%_50%] lg:grid-cols-[40%_60%] h-screen">
            <div className="bg-[#FAFBFE] p-[30px] grid place-items-center h-full overflow-y-auto">
                <div className="w-full">
                    <img src={agroLogo} className="w-36 mb-[27px]" alt="Agro Market Square" />
                    <Outlet />
                </div>
            </div>

            <div
                className="border-l h-full overflow-hidden max-md:hidden"
                style={{ background: `url(${signInImg}) no-repeat center`, backgroundSize: 'contain' }}
            />
        </div>
    );
};

export default OnboardLayout;
