const Table = ({ titles, children, className, tableClassName }) => {
    return (
        <div className="overflow-x-auto rounded mt-5">
            <table className={`min-w-max xl:w-full ${tableClassName}`}>
                <thead className="bg-[#FAFBFE]">
                    <tr
                        className={`w-full [&>*]:px-5 [&>*]:py-3 [&>*:first-of-type]:pl-3 text-center [&>*]:font-semibold grid ${className}`}
                    >
                        {titles.map(({ title }, idx) => (
                            <th className={`cursor-pointer`} key={title + idx}>
                                <p className={`flex items-center justify-between`}>{title}</p>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>{children}</tbody>
            </table>
        </div>
    );
};

export default Table;
