import moment from 'moment';
import { Link } from 'react-router-dom';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import ROUTES from 'constants/routes';

const Notification = ({ id, title, content, time, className, link, read, openPopup }) => {
    const notNotificationsPage = window.location.pathname !== ROUTES.NOTIFICATIONS;

    // If the user is not admin and not requisitions and retailers
    // New table notification
    // Edit table notification

    // Requests for admins and specified roles
    // If admin or finance
    // New requisition request
    // If admin or wholesale manager
    // New retailer request

    // Notification responses
    // Update on requisiton request
    // Update on retailers request

    const markAsRead = async () => {
        // change read to true
        // update the store values generally

        try {
            // const notificationRef = doc(db, 'notifications/admin/admin', 'admin');
            // await updateDoc(userRef, { role, updatedAt: serverTimestamp() });
            // const userSnapshot = await getDoc(userRef);
            // const _user = {
            //     ...userSnapshot.data(),
            //     ...formatDateValues(userSnapshot.data()),
            // };
            // users[idx] = _user;
            // dispatch(setUsers(users));
        } catch (error) {
            // toast.error(error?.message);
        }
    };

    return (
        <Link
            to={link ?? ''}
            onClick={() => {
                notNotificationsPage && openPopup(false);
                !read && markAsRead();
            }}
            className={`relative block bg-white w-full py-5 pl-7 pr-5 ${read && 'hover:bg-gray-100'} ${className}`}
        >
            {read ? <span className="absolute h-2 w-2 rounded-full bg-primary-light left-3 top-[25.5px]" /> : null}
            <p className="cursor-pointer font-semibold">{title}</p>
            <p className="text-gray-500 !ml-0 mt-[3px]">{`${content}`}</p>

            <p className="text-gray-400 mt-2 text-[12px] !ml-0 ">{capitalizeFirstLetter(moment(time).fromNow())}</p>
        </Link>
    );
};

export default Notification;
