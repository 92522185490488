const dev = {
    FB: {
        apiKey: 'AIzaSyA-n4ZtpGEbcKDpvBN-cFQtmFuKn0VSIEM',
        authDomain: 'ams-warehouse-66f2f.firebaseapp.com',
        projectId: 'ams-warehouse-66f2f',
        storageBucket: 'ams-warehouse-66f2f.appspot.com',
        messagingSenderId: '86837645491',
        appId: '1:86837645491:web:393dd8ef77361bf323a0c6',
        measurementId: 'G-7PR2S9NGBR',
    },
    API_URL: 'https://ams-warehouse-backend-dev.onrender.com/',
};

const prod = {
    FB: {
        apiKey: 'AIzaSyBqt0uaMlOoWbjR_jwJJR_illDpGXEErhU',
        authDomain: 'agro-ams-reporting.firebaseapp.com',
        projectId: 'agro-ams-reporting',
        storageBucket: 'agro-ams-reporting.appspot.com',
        messagingSenderId: '678701288774',
        appId: '1:678701288774:web:5eefe825cc78b4a36ea947',
        measurementId: 'G-F29MN1R47W',
    },
    API_URL: 'https://ams-warehouse-backend.onrender.com/',
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default config;
