import React from 'react';
import getInitials from 'utils/getInitials';

const Avatar = ({ img, fullName, className }) => {
    return (
        <>
            {img ? (
                <img
                    src={img}
                    alt={fullName}
                    className={`rounded-full object-cover w-[40px] h-[40px] mr-5 ${className}`}
                />
            ) : (
                <span
                    className={`rounded-full w-[40px] h-[40px] mr-5 bg-primary text-white grid place-items-center font-medium ${className}`}
                >
                    {getInitials(fullName)}
                </span>
            )}
        </>
    );
};

export default Avatar;
