import React from 'react';

const Modal = ({ children, closeModal, showCloseIcon = true }) => {
  return (
    <div
      onClick={closeModal}
      className="bg-black/40 backdrop-blur-[3px] fixed left-0 top-0 w-screen h-screen z-[9000] grid place-items-center"
    >
      <div onClick={(evt) => evt.stopPropagation()}>{children}</div>

      {showCloseIcon && (
        <span className="material-symbols-outlined text-white text-3xl absolute right-9 top-6 cursor-pointer font-bold bg-opacity-100">
          close
        </span>
      )} 
    </div>
  );
};

export default Modal;
