const ROUTES = Object.freeze({
    LOGIN: '/login',
    REGISTER: '/register',
    RECOVER: '/recover-password',

    HOMEPAGE: '/',
    TRADES: '/trades',
    SALES: '/sales',
    REQUISITIONS: '/requisitions',
    EXPENSES: '/expenses',

    FINANCE_REQUISITIONS: '/finance/requisitions',

    RETAILERS: '/retailers',
    RETAILERS_REQUESTS: '/retailers/requests',
    STORAGE: '/storage',
    LOGISTICS: '/logistics',
    INSPECTIONS: '/inspections',
    ONLINE: '/online-sales',
    CONTRIBUTION_MARGIN: '/contribution-margin',
    OPERATING_INCOME: '/operating-income',
    INVENTORY: '/inventory',

    PROFILE: '/profile',
    NOTIFICATIONS: '/notifications',
});

export default ROUTES;
